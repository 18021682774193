<template>
  <div>

    <h5 class="mb-20">Last logged in: {{ formatDateAndTimeAmPm(user.last_login) }}</h5>
    <hr>

    <h5 class="mb-20">User Account Details</h5>

    <b-row>
      <b-col cols="6">
        <b-form-group
            label="Your Display Name"
            class="mb-0"
        >
          <b-input-group class="mb-20">
            <b-input
                v-model="user.name"
                type="text"
                :disabled="true"
                class="form-control standard-input"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
            label="Your Login Username"
            class="mb-0"
        >
          <b-input-group class="mb-20">
            <b-input
                v-model="user.email"
                type="text"
                :disabled="true"
                class="form-control standard-input"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {dateFormat} from "@/mixins/dateFormat";

export default {
  name: 'UserOverview',
  mixins: [dateFormat],
  computed: {
    user() {
      return this.$store.getters.user
    }
  }
}
</script>

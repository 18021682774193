<template>
  <div class="border-bottom-gray-200 pb-80 mt-20">
    <div class="container">
      <div class="section-title">
        <h4 class="p-3 mb-2">
          <span>Settings</span>
        </h4>
      </div>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="Overview" active>
            <UserOverview />
          </b-tab>
          <b-tab title="Password">
            <ChangePasswordForm/>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from "@/views/introducer/settings/ChangePasswordForm";
import UserOverview from "@/views/client/settings/UserOverview";

export default {
  name: 'Settings',
  components: {UserOverview, ChangePasswordForm},
  computed: {
    user() {
      return this.$store.getters.user
    }
  }
}
</script>

<template>

    <div class="main-content questionnaire">
      <div class="fieldset">
        <b-row>
          <b-col cols="6">
            <b-form-group
                label="Current Password"
                label-for="password-old"
                class="mb-0"
            >
              <b-input-group class="mb-20">
                <b-form-input
                    id="password-old"
                    v-model="form.old_password"
                    placeholder="Old Password"
                    size="lg"
                    type="password"
                    :state="errors.old_password.length ? false : null"
                />
                <template #append>
                  <b-input-group-text class="bg-primary"
                  ><i class="text-20 text-white i-Password-Field"></i>
                  </b-input-group-text>
                </template>
              </b-input-group>
              <b-form-invalid-feedback
                  id="password-old-feedback"
                  :state="errors.old_password.length ? false : null"
              >
                <p v-for="(error, index) in errors.old_password" :key="index+'errorOld'"> {{ error }}</p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group
                label="New Password"
                label-for="password-new"
                class="mb-0"
            >

              <b-input-group class="mb-20">
                <b-form-input
                    id="password-new"
                    v-model="form.new_password"
                    placeholder="New Password"
                    size="lg"
                    type="password"
                    :state="errors.new_password.length ? false : null"
                />
              </b-input-group>
              <b-form-invalid-feedback
                  id="password-new-feedback"
                  :state="errors.new_password.length ? false : null"
              >
                <p v-for="(error, index) in errors.new_password" :key="index+'errorNew'"> {{ error }}</p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group
                label="Repeat New Password"
                label-for="password-repeat"
                class="mb-0"
            >

              <b-input-group class="mb-20">
                <b-form-input
                    id="password-repeat"
                    v-model="form.repeat_password"
                    placeholder="Repeat New Password"
                    size="lg"
                    type="password"
                    :state="errors.repeat_password.length ? false : null"
                />
              </b-input-group>
              <b-form-invalid-feedback
                  id="password-repeat-feedback"
                  :state="errors.repeat_password.length ? false : null"
              >
                <p v-for="(error, index) in errors.repeat_password" :key="index+'errorRepeat'"> {{ error }}</p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button @click="changePassword">Change Password</b-button>
      </div>
    </div>
</template>

<script>
import {http} from "@/services";
import {toast} from '@/mixins/toast'

export default {
  name: 'ChangePasswordForm',
  mixins: [toast],
  data() {
    return {
      form: {
        old_password: null,
        new_password: null,
        repeat_password: null
      },
      errors: {
        old_password: [],
        new_password: [],
        repeat_password: []
      }
    }
  },
  methods: {
    changePassword(bvEvent) {
      bvEvent.preventDefault()
      this.clearErrors()
      http.patch('password_change', this.form).then(
          () => {
            this.toast('Password changed', 'Success')
            this.clearForm()
          }
      ).catch(
          error => {
            this.handleErrors(error.response.data)
          }
      )
    },
    handleErrors(errors) {
      let keys = Object.keys(errors);
      for (let i in keys) {
        this.errors[keys[i]] = errors[keys[i]];
      }
    },
    clearErrors() {
      let keys = Object.keys(this.errors)
      for (let i in keys) {
        this.errors[keys[i]] = []
      }
    },
    clearForm() {
      let keys = Object.keys(this.form)
      for (let i in keys) {
        this.form[keys[i]] = null
      }
    }
  }
}
</script>
